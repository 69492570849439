import {HttpClient, HttpHeaders, HttpParams, HttpEventType} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject} from 'rxjs';
import { map, retry, tap, concatMap, catchError } from 'rxjs/operators';
import { FileInfoAPI } from '../model/load-file-model';
import { environment } from '../../environments/environment';
import {SasToken} from 'src/app/model/load-file-model'
import {AnonymousCredential, BlockBlobURL, ContainerURL, ServiceURL, StorageURL, uploadBrowserDataToBlockBlob, Aborter} from '@azure/storage-blob';
import {ApiService} from 'src/app/api.service'
import {Router} from '@angular/router';
import {forkJoin} from 'rxjs'
const uploadApi = environment.file;

@Injectable({
     providedIn: 'root'
})
export class LoadFilesService {
     private headers: HttpHeaders;
     private user_id: string;
     private org_id: string;
     size;
     private readonly azureStorageUrl = environment.azureStorageUrl;
     private containerURL: ContainerURL;
     private readonly containerName = environment.usersFileContainerName
     private sasToken = '';
     private filePath = '';
     blockIds : string[] = [] 
     private apiURL = environment.file;
     // private apiURL = "http://127.0.0.1:8000/file/";

     constructor(private http: HttpClient, public progress: ApiService, private router: Router) {
          if (!this.user_id || !this.org_id) {
               this.user_id = JSON.parse(localStorage.getItem('user')).user_id;
               this.org_id = JSON.parse(localStorage.getItem('user')).org_id;
          }
          this.headers = this.getAuthHeaders();
     }

     private getAuthHeaders(): HttpHeaders {
          const token = JSON.parse(localStorage.getItem('user')).token;
          const headers = new HttpHeaders().append('Authorization', `Bearer ${token}`);
          return headers;
     }



     sasTokenGenerator(){
         
          let option = {
               headers: this.getAuthHeaders(),
               
          }
   
     this.http.get(this.apiURL +'sas_token/', option).subscribe((data : SasToken)=>{
            this.sasToken = data.sas_token;
       },
       (error)=>{
         
       } 
       );
     }



     getFileInfo(ref_genom: string, seq_platform: string): Observable<FileInfoAPI[]> {
          let params = new HttpParams();
          params = params.append('ref_genome', ref_genom);
          params = params.append('seq_platform', seq_platform);
          return this.http.get<FileInfoAPI[]>(`${uploadApi}get_file_info/`, {headers: this.getAuthHeaders(), params } )
          .pipe(
               map((res: FileInfoAPI[]) => res)
          );
     }



     createFileInURL(payLoad: FormData): Observable<any> {

      return this.http.post(`${this.apiURL}create_file/`, payLoad, {headers: this.getAuthHeaders(),reportProgress: true, observe: 'events' }).pipe(
        map(((response: any) => response),
   ));
     }


     createFile(payLoad: FormData): Observable<any> {

      return this.http.post(`${this.apiURL}create_file/`, payLoad, {headers: this.getAuthHeaders()});
 }




     async uploadFile(file: File, file_path:string, file_id?: number, user_file_name?: string): Promise<string> {
      
          const pipeline = StorageURL.newPipeline(new AnonymousCredential(), {
            retryOptions: { maxTries: 4 }, // Adjust this as needed
            
            });
          const serviceURL = new ServiceURL(
            `${this.azureStorageUrl}?${this.sasToken}`,
            pipeline
          );
          this.containerURL = ContainerURL.fromServiceURL(serviceURL, this.containerName);
          const blobURL = BlockBlobURL.fromContainerURL(this.containerURL, file_path);
      
          let aborter = Aborter.none;
          await uploadBrowserDataToBlockBlob(aborter,file, blobURL, {
            blockSize: 20 * 1024 * 1024, // 4MB block size
            parallelism: 20, // 20 concurrent requests
            progress: (ev) => {
              let percentage = Math.floor((ev.loadedBytes / file.size) * 100);
              if(percentage == 100) {
                    this.progress.removeMultiFileProgress(file_id);
                    // this.progress.removeMultiFileNames(user_file_name);
              } else {
                    this.progress.addMultiFileProgress({
                         user_file_name: user_file_name,
                         progress: percentage,
                         file_id: file_id
                    })
          }
            },
          })
          return this.filePath;
        }


        
        getFilePath(payload:any):Observable<any>{
          // let params = new HttpParams();
          // params = params.append('analysis_type', analysis_type).append('file_extension', file_extension).append('file_type', file_type).append('file_sub_type', file_sub_type)
          return this.http.post(`${this.apiURL}file_path/`,payload ,{headers: this.getAuthHeaders()}).pipe(tap((data) => {
               
          }, (error) => {
            if (error.status === 401) {
              setTimeout(() => {
                this.router.navigate(['/login']);
              }, 5000);
            }
          }));
        }

        getSizeOfFile(fileUrl): Observable<any>{
          return this.http.head(fileUrl, { observe: 'response', responseType: 'text' })
        }




        async streamFileAndUpload(fileUrl: string, file_path: string, headerOfFile): Promise<void> {

          const pipeline = StorageURL.newPipeline(new AnonymousCredential(), {
               retryOptions: { maxTries: 4 }, // Adjust this as needed
               
               });
          const serviceURL = new ServiceURL(
               `${this.azureStorageUrl}?${this.sasToken}`,
               pipeline
             );
             this.containerURL = ContainerURL.fromServiceURL(serviceURL, this.containerName);
             const blobURL = BlockBlobURL.fromContainerURL(this.containerURL, file_path);


          // ANOTHER APROACH To DO STREAM, BUT FIRST IN THIS APPROCH WE HAVE TO UPLOAD THE WHOLE FILE IN MEMORY OF CLIENT SYSTEM
          // Open a readable stream from the URL
          // this.http.get(fileUrl, { responseType: 'arraybuffer', observe: 'response' }).forEach(data=>{

          //      blobURL.upload(Aborter.none, data.body, data.body.byteLength, {
          //           progress: (ev) => {
          //             let percentage = Math.floor((ev.loadedBytes / data.body.byteLength) * 100);
          //             this.progress.next(percentage)
          //           },
          //         });
          // });

          const totalSize = headerOfFile.headers.get('Content-Length')
          let chunkSize = 15* 1024 * 1024;
          let blockId = 1;
          const totalChunks = Math.ceil(totalSize / chunkSize);
          let uploadedSize = 0;



          const readAndUploadChunk = async (start: number, end: number): Promise<void> => {

            try{
              let id = String(blockId).padStart(6, '0');
               const hasValue = btoa(`${id}`);
               this.blockIds.push(hasValue);
               let blobChunk = await this.readBlobChunk(fileUrl, start, end);
               blobURL.stageBlock(Aborter.none, hasValue, blobChunk, chunkSize).then(()=>{
                uploadedSize += end - start + 1
                blobChunk = new Blob();
                const progress = Math.floor((uploadedSize / totalSize) * 100);

               this.progress.progressLoadFiles.next(progress);

                if(uploadedSize >= totalSize){
                  this.commitBlockList(blobURL);
                  this.progress.isFileUploaded.next('Yes');
                }
               });

               blockId = blockId + 1;
               if (totalChunks >= blockId) {
                 readAndUploadChunk(end + 1, Math.min(end + 1 + chunkSize, totalSize));
               } 
             }
             catch{
              this.progress.isFileUploaded.next('Error');
             }

            }

            readAndUploadChunk(0, Math.min(chunkSize, totalSize));

        }





     private async readBlobChunk(fileUrl: string, start: number, end: number): Promise<Blob> {
      const chunkUrl = fileUrl;
      const chunkHeaders = new HttpHeaders({
        Range: `bytes=${start}-${end}`,
      });
      const response = await this.http.get(chunkUrl, { headers: chunkHeaders, responseType: 'blob'}).toPromise()
     return response;
     }


      
     private async commitBlockList(blobURL: BlockBlobURL): Promise<void> {
          await blobURL.commitBlockList(Aborter.none, this.blockIds);
        }



     deleteFile(file_id: number): Observable<string> {
          let params = new HttpParams();
          params = params.append('file_id', file_id.toString());
          return this.http.delete(`${uploadApi}delete_file/`, {headers: this.getAuthHeaders(), params }).pipe(
               map((response: string) => response)
          );
     }

     getFileDetails(params: HttpParams): Observable<FileInfoAPI[]> {
          return this.http.get(`${uploadApi}file_details/`, {headers: this.getAuthHeaders(), params: params})
          .pipe(
               map((res: FileInfoAPI[]) => res)
          );
     }

     awsUpload(url: string, file: File, file_id?: number, user_file_name?: string): Observable<any> {
          let head = new HttpHeaders();
          const formData: FormData = new FormData();
          formData.append('file', file);
          return this.http.put(url, file, {headers: head, reportProgress: true, observe: 'events' })
          .pipe(
               map((event) => {
                    switch (event.type) {
                         case HttpEventType.UploadProgress:
                              const progress = Math.round((100 * event.loaded) / event.total);
                              if(progress >= 100) {
                                   this.progress.removeMultiFileProgress(file_id);
                                   // this.progress.removeMultiFileNames(user_file_name);
                              } else {
                                   this.progress.addMultiFileProgress({
                                        user_file_name: user_file_name,
                                        progress: progress,
                                        file_id: file_id
                                   })
                              }
                              return { status: 'progress', message: progress };
             
                         case HttpEventType.Response:
                           return event.status;
                         default:
                           return `Unhandled event: ${event.type}`;
                    }
               })
          );
     }
     
}
